$(document).ready(function () {
    var $fakeInput = $('.js-fake-input');

    if (0 === $fakeInput.length) {
        return;
    }

    var $fakeInputTrigger = $fakeInput.find('.js-fake-input-trigger');
    var $fakeInputTarget = $fakeInput.find('.js-fake-input-target');

    $fakeInputTrigger.on('input', function() {
        $fakeInputTarget.val($fakeInputTrigger.val());
    });
});
