$(document).ready(function () {
    var $dateRedirect = $('.js-date-redirect');

    if (0 === $dateRedirect.length) {
        return;
    }

    $dateRedirect.change(function() {
        var parameters = {'minimal_start_date': $(this).val()};

        if ($dateRedirect[0].hasAttribute('data-display-canceled')) {
            parameters['display_canceled'] = true;
        }

        window.location.href = Routing.generate('session_view_all_visio_inter_entreprise', parameters);
    });
});
