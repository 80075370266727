$(document).ready(function () {
    var $currencyInput = $('.js-currency-input');

    if (0 === $currencyInput.length) {
        return;
    }

    function formatNumber(number) {
        // format number 1000000 to 1 000 000
        return number.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    function formatDecimalPart(number) {
        // format decimal part to 00 or 50
        return number.replace(/\D/g, '').replace(/0[1-9]+/g, '00').replace(/[1-9]/g, '5').replace(/([1-9]\d+)/g, '50');
    }

    function formatCurrency($input, blur) {
        // validates decimal side
        // and puts cursor back in right position.

        // get input value
        var inputVal = $input.val();

        // don't validate empty input
        if ('' === inputVal) {
            return;
        }

        // original length
        var originalLen = inputVal.length;

        // initial caret position
        var caretPos = $input.prop('selectionStart');

        // force usage of "," to separate decimal part (vs ".")
        if (0 <= inputVal.indexOf('.')) {
            inputVal = inputVal.replace(/\./, ',');
        }

        // check for decimal
        if (0 <= inputVal.indexOf(',')) {
            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimalPos = inputVal.indexOf(',');

            // split number by decimal point
            var leftSide = inputVal.substring(0, decimalPos);
            var rightSide = inputVal.substring(decimalPos);

            leftSide = formatNumber(leftSide);
            rightSide = formatDecimalPart(rightSide);

            // On blur make sure 2 numbers after decimal
            if ('blur' === blur) {
                rightSide += '00';
            }

            // Limit decimal to only 2 digits
            rightSide = rightSide.substring(0, 2);

            // join number by ","
            inputVal = leftSide + ',' + rightSide;
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            inputVal = formatNumber(inputVal);

            // final formatting
            if ('blur' === blur) {
                inputVal += ',00';
            }
        }

        // send updated string to input
        $input.val(inputVal);

        // put caret back in the right position
        var updatedLen = inputVal.length;
        caretPos = updatedLen - originalLen + caretPos;
        $input[0].setSelectionRange(caretPos, caretPos);
    }

    $currencyInput.on({
        keyup: function() {
            formatCurrency($(this));
        },
        blur: function() {
            formatCurrency($(this), 'blur');
        }
    });
});
