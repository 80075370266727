$(document).ready(function () {
    var $conditionalDisplay = $('.js-conditional-display');

    if (0 === $conditionalDisplay.length) {
        return;
    }

    var $conditionalDisplayTrigger = $conditionalDisplay.find('.js-conditional-display-trigger');
    var $conditionalDisplayTargets = $conditionalDisplay.find('.js-conditional-display-target');
    var $currentDisplayedTarget = null;

    var displayTarget = function(triggerValue) {
        $.each($conditionalDisplayTargets, function() {
            var targetDisplayValues = JSON.parse($(this).attr('data-trigger-values'));

            if (targetDisplayValues.includes(triggerValue)) {
                if ($currentDisplayedTarget === $(this)) {
                    //break the $.each loop
                    return false;
                }

                if (null !== $currentDisplayedTarget) {
                    $currentDisplayedTarget.addClass('d-none');
                }

                $currentDisplayedTarget = $(this);
                $currentDisplayedTarget.removeClass('d-none');

                //break the $.each loop
                return false;
            }
        });
    };

    var triggerInitialValue = $conditionalDisplayTrigger.val();

    if (triggerInitialValue) {
        displayTarget(triggerInitialValue);
    }

    $conditionalDisplayTrigger.change(function() {
        displayTarget($(this).val());
    });
});
