import './vendor_js_new_design';

import '../assets/javascripts/aside_block';
import '../assets/javascripts/autoload_popup';
import '../assets/javascripts/click_transmitter';
import '../assets/javascripts/clipboard_copy';
import '../assets/javascripts/coach_assignment';
import '../assets/javascripts/coach_compensation';
import '../assets/javascripts/comment';
import '../assets/javascripts/conditional_display';
import '../assets/javascripts/currency_input';
import '../assets/javascripts/custom_form';
import '../assets/javascripts/date_picker';
import '../assets/javascripts/date_redirect';
import '../assets/javascripts/distance_selector';
import '../assets/javascripts/error_scrollable';
import '../assets/javascripts/fake_input';
import '../assets/javascripts/hour_mask';
import '../assets/javascripts/infinite_scroll';
import '../assets/javascripts/link_disabling';
import '../assets/javascripts/member_choice';
import '../assets/javascripts/member_pass_infos';
import '../assets/javascripts/membership_infos';
import '../assets/javascripts/mobile_menu';
import '../assets/javascripts/nav_dropdown';
import '../assets/javascripts/nb_applications_form';
import '../assets/javascripts/notification_link';
import '../assets/javascripts/organization_selector';
import '../assets/javascripts/partner_pass_grant';
import '../assets/javascripts/session_carousel';
import '../assets/javascripts/show_up';
import '../assets/javascripts/sport_selector';
import '../assets/javascripts/session_selector';
import '../legacy/javascripts/recurrence_selector';
import '../assets/javascripts/tab';
import '../assets/javascripts/team_search';
import '../assets/javascripts/toggle_set';
import '../assets/javascripts/tooltips';

//Used by the address_autocomplete in our .html.twig file
//TODO: Improve this
const $ = require('jquery');
global.$ = global.jQuery = $;
