$(document).ready(function () {
    var $clipboardCopy = $('.js-clipboard-copy');

    if (0 === $clipboardCopy.length) {
        return;
    }

    $.each($clipboardCopy, function(){
        var $clipboardCopyInput = $(this).find('.js-clipboard-copy-input');
        var $clipboardCopyAction = $(this).find('.js-clipboard-copy-action');

        $clipboardCopyAction.click(function () {
            $clipboardCopyInput.select();
            document.execCommand('copy');
            alert('Lien copié');
        });
    });
});
