$(document).ready(function () {
    var $teamSearch = $('.js-team-search');

    if (0 === $teamSearch.length) {
        return;
    }

    var dataSearchUrl = $teamSearch.attr('data-search-url');
    var $teamSearchInput = $teamSearch.find('.js-team-search-input');
    var $teamSearchTrigger = $teamSearch.find('.js-team-search-trigger');

    $teamSearchTrigger.click(function() {
        var terms = $teamSearchInput.val();

        window.location.href = Routing.generate(dataSearchUrl, {'terms': terms});
    });
});
