$(document).ready(function () {
    var $datePickers = $('.js-date-picker');

    if (0 === $datePickers.length) {
        return;
    }

    $.each($datePickers, function(){
        var $datePicker = $(this);

        var datePickerOptions = {
            autoclose: true,
            format: 'dd/mm/yyyy',
            toggleActive: true,
            defaultViewDate: $datePicker.attr('data-date-format'),
            language: 'fr-FR',
            weekStart: 1,
        };

        if ($datePicker[0].hasAttribute('data-end-date')) {
            datePickerOptions['endDate'] = $datePicker.attr('data-end-date');
        }

        if ($datePicker[0].hasAttribute('data-start-date')) {
            datePickerOptions['startDate'] = $datePicker.attr('data-start-date');
        }

        if ($datePicker[0].hasAttribute('data-orientation')) {
            datePickerOptions['orientation'] = $datePicker.attr('data-orientation');
        }

        $datePicker.datepicker(datePickerOptions);
    });
});
