$(document).ready(function () {
    var $errorScrollableForm = $('.js-error-scrollable');

    if (0 === $errorScrollableForm.length) {
        return;
    }

    var $scrollableFormErrors = $errorScrollableForm.find('.js-error-scrollable-error');

    if (0 < $scrollableFormErrors.length) {
        $('html, body').animate({
            scrollTop: $scrollableFormErrors.first().position().top
        }, 1000);
    };
});
