/*
 * Simple Mobile Navigation
 */
(function($) {
    function MobileNav(options) {
        this.options = $.extend({
            container: null,
            hideOnClickOutside: false,
            menuActiveClass: 'nav-active',
            menuOpener: '.nav-opener',
            menuDrop: '.nav-drop',
            toggleEvent: 'click',
            outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
        }, options);

        this.initStructure();
        this.attachEvents();
    }

    MobileNav.prototype = {
        initStructure: function() {
            this.page = $('html');
            this.container = $(this.options.container);
            this.opener = this.container.find(this.options.menuOpener);
            this.drop = this.container.find(this.options.menuDrop);
        },
        attachEvents: function() {
            var self = this;

            if(activateResizeHandler) {
                activateResizeHandler();
                activateResizeHandler = null;
            }

            this.outsideClickHandler = function(e) {
                if (self.isOpened()) {
                    var $target = $(e.target);

                    //TODO: find a better way than using the class "select2-selection__choice__remove" or "pac-container" to prevent hiding menu
                    if(!$target.closest(self.opener).length && !$target.closest(self.drop).length && !$target.closest('.js-mobile-menu-outside-click-exception').length && !$target.closest('.select2-selection__choice__remove').length && !$target.closest('.pac-container').length) {
                        self.hide();
                    }
                }
            };

            this.openerClickHandler = function(e) {
                e.preventDefault();
                self.toggle();
            };

            this.opener.on(this.options.toggleEvent, this.openerClickHandler);
        },
        isOpened: function() {
            return this.container.hasClass(this.options.menuActiveClass);
        },
        show: function() {
            this.container.addClass(this.options.menuActiveClass);

            if (this.options.hideOnClickOutside) {
                this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
            }
        },
        hide: function() {
            this.container.removeClass(this.options.menuActiveClass);

            if (this.options.hideOnClickOutside) {
                this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
            }
        },
        toggle: function() {
            if (this.isOpened()) {
                this.hide();
            } else {
                this.show();
            }
        },
        destroy: function() {
            this.container.removeClass(this.options.menuActiveClass);
            this.opener.off(this.options.toggleEvent, this.clickHandler);
            this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
        }
    };

    var activateResizeHandler = function() {
        var $win = $(window),
            $doc = $('html'),
            resizeClass = 'resize-active',
            flag, timer;

        var removeClassHandler = function() {
            flag = false;
            $doc.removeClass(resizeClass);
        };

        var resizeHandler = function() {
            if(!flag) {
                flag = true;
                $doc.addClass(resizeClass);
            }

            clearTimeout(timer);
            timer = setTimeout(removeClassHandler, 500);
        };

        $win.on('resize orientationchange', resizeHandler);
    };

    $.fn.mobileNav = function(opt) {
        var args = Array.prototype.slice.call(arguments);
        var method = args[0];

        return this.each(function() {
            var $container = jQuery(this);
            var instance = $container.data('MobileNav');

            //TODO: if I don't comment this if condition, then if I display the mobile nav, than enlarge the window above 767px until the point
            //where mobile nav is disabled, and then reduce once again the window size until the mobile nav is enabled again, then the mobile nav opener does
            //not work anymore and I cannot open the mobile nav again
            //=> first understand what this code is about than find a suitable way to do this
            // if (typeof opt === 'object' || typeof opt === 'undefined') {
                $container.data('MobileNav', new MobileNav($.extend({
                    container: this
                }, opt)));
            // } else if (typeof method === 'string' && instance) {
            //     if (typeof instance[method] === 'function') {
            //         args.shift();
            //         instance[method].apply(instance, args);
            //     }
            // }
        });
    };
}(jQuery));

/*
 * Responsive Layout helper
 */
window.ResponsiveHelper = (function($) {
    // init variables
    var handlers = [];
    var prevWinWidth;
    var $win = $(window);
    var nativeMatchMedia = false;

    // detect match media support
    if(window.matchMedia) {
        if(window.Window && window.matchMedia === Window.prototype.matchMedia) {
            nativeMatchMedia = true;
        } else if(window.matchMedia.toString().indexOf('native') > -1) {
            nativeMatchMedia = true;
        }
    }

    // prepare resize handler
    function resizeHandler() {
        var winWidth = $win.width();

        if (winWidth !== prevWinWidth) {
            prevWinWidth = winWidth;

            // loop through range groups
            $.each(handlers, function(index, rangeObject){
                // disable current active area if needed
                $.each(rangeObject.data, function(property, item) {
                    if(item.currentActive && !matchRange(item.range[0], item.range[1])) {
                        item.currentActive = false;

                        if(typeof item.disableCallback === 'function') {
                            item.disableCallback();
                        }
                    }
                });

                // enable areas that match current width
                $.each(rangeObject.data, function(property, item) {
                    if(!item.currentActive && matchRange(item.range[0], item.range[1])) {
                        // make callback
                        item.currentActive = true;

                        if(typeof item.enableCallback === 'function') {
                            item.enableCallback();
                        }
                    }
                });
            });
        }
    }

    $win.bind('load resize orientationchange', resizeHandler);

    // test range
    function matchRange(r1, r2) {
        var mediaQueryString = '';

        if(r1 > 0) {
            mediaQueryString += '(min-width: ' + r1 + 'px)';
        }

        if(r2 < Infinity) {
            mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
        }

        return matchQuery(mediaQueryString, r1, r2);
    }

    // media query function
    function matchQuery(query, r1, r2) {
        if(window.matchMedia && nativeMatchMedia) {
            return matchMedia(query).matches;
        }

        if(window.styleMedia) {
            return styleMedia.matchMedium(query);
        }

        if(window.media) {
            return media.matchMedium(query);
        }

        return prevWinWidth >= r1 && prevWinWidth <= r2;
    }

    // range parser
    function parseRange(rangeStr) {
        var rangeData = rangeStr.split('..');
        var x1 = parseInt(rangeData[0], 10) || -Infinity;
        var x2 = parseInt(rangeData[1], 10) || Infinity;

        return [x1, x2].sort(function(a, b) {
            return a - b;
        });
    }

    // export public functions
    return {
        addRange: function(ranges) {
            // parse data and add items to collection
            var result = {data:{}};

            $.each(ranges, function(property, data){
                result.data[property] = {
                    range: parseRange(property),
                    enableCallback: data.on,
                    disableCallback: data.off
                };
            });

            handlers.push(result);

            // call resizeHandler to recalculate all events
            prevWinWidth = null;
            resizeHandler();
        }
    };
}(jQuery));

// mobile menu init
function initMobileNav() {
    ResponsiveHelper.addRange({
        '..': {
            on: function() {
                jQuery('body').mobileNav({
                    menuActiveClass: 'mobile-menu-active',
                    menuOpener: '.mobile-menu-opener',
                    hideOnClickOutside: true,
                    menuDrop: '.mobile-menu-holder'
                });
            },
            off: function() {
                jQuery('body').mobileNav('destroy');
            }
        }
    });

    ResponsiveHelper.addRange({
        '..': {
            on: function() {
                jQuery('body').mobileNav({
                    menuActiveClass: 'header-search-active',
                    menuOpener: '.header-search-opener',
                    hideOnClickOutside: true,
                    menuDrop: '.header-search-holder'
                });
            },
            off: function() {
                jQuery('body').mobileNav('destroy');
            }
        }
    });
}

jQuery(function() {
    initMobileNav();
});
