$(document).ready(function () {
    var $coachAssignmentFieldContainer = $('.js-coach-assignment-fields-container');

    if (0 === $coachAssignmentFieldContainer.length) {
        return;
    }

    var $coachAssignmentClear = $coachAssignmentFieldContainer.find('.js-coach-assignment-clear');
    var $coachAssignmentMemberInput = $coachAssignmentFieldContainer.find('.js-coach-assignment-member-input');
    console.log($coachAssignmentMemberInput);

    $coachAssignmentClear.click(function() {
        var $inputs = $coachAssignmentFieldContainer.find('input');
        $inputs.empty();
        $inputs.val('');

        $coachAssignmentMemberInput.val('').trigger('change');
    });
});
