$(document).ready(function() {
    var $showUp = $('.js-show-up');

    if (0 === $showUp.length) {
        return;
    }

    var $showUpApplicantBoxesPartner = $showUp.find('.js-show-up-applicant-box-partner');
    var $showUpApplicationListInput = $showUp.find('.js-show-up-application-list-input');
    var $showUpModalToggle = $showUp.find('.js-show-up-modal-toggle');
    var $showUpList = $showUp.find('.js-show-up-list');
    var $showUpValidationForm = $showUp.find('.js-show-up-validation-form');
    var $showUpPostSubmitModal = $showUp.find('.js-show-up-post-submit-modal');
    var $showUpPostSubmitModalMessage = $showUp.find('.js-show-up-post-submit-modal-message');
    var $showUpPostSubmitModalTitle = $showUp.find('.js-show-up-post-submit-modal-title');
    var $showUpValidationModal = $showUp.find('.js-show-up-validation-modal');
    var $showUpPartnerModalConfirms = $showUp.find('.js-show-up-applicant-box-partner-modal-confirm');

    var handleApplicantBoxDisplay = function($applicantBox) {
        $applicantBox.addClass('applicants-box-not-showed-up-clicked');
        $applicantBox.find('.js-show-up-applicant-box-cross').toggleClass('d-none');
    };

    var addApplicationToShowUpSubmit = function($applicantBox) {
        $applicantBox.addClass('js-show-up-applicant-box-clicked');

        var applicationId = $applicantBox.attr('data-application-id');
        $showUpModalToggle.removeClass('disabled');
        $showUpModalToggle.attr('data-target', '#show-up-validation-modal');
        var $participantListItem = $('<li></li>');
        $participantListItem.attr('data-application-id', applicationId);
        $participantListItem.text($applicantBox.attr('data-applicant-name'));
        $showUpList.append($participantListItem);

        var newWidget = $("#allsessions_show_up_validation_applicationIds").attr('data-prototype');
        var $newWidget = $(newWidget.replace(/__name__/g, applicationId));
        $newWidget.val(applicationId);
        $showUpApplicationListInput.append($newWidget);
    };

    var removeApplicationFromShowUpSubmit = function($applicantBox) {
        $applicantBox.removeClass('js-show-up-applicant-box-clicked').removeClass('applicants-box-not-showed-up-clicked');

        if (0 === $showUp.find('.js-show-up-applicant-box-clicked').length) {
            $showUpModalToggle.addClass('disabled');
            $showUpModalToggle.attr('data-target', '#show-up-explanation');
        }

        var applicationId = $applicantBox.attr('data-application-id');
        $showUpList.find(`li[data-application-id='${applicationId}']`).remove();

        var $hiddenInput = $showUpApplicationListInput.find('input[value=' + applicationId +'][type=\'hidden\']');
        $hiddenInput.remove();
    };

    $showUp.on('click', '.js-show-up-applicant-box', function(e) {
        e.preventDefault();

        var $applicantBox = $(this);
        handleApplicantBoxDisplay($applicantBox);

        if (!$applicantBox.hasClass('js-show-up-applicant-box-clicked')) {
            addApplicationToShowUpSubmit($applicantBox);
        } else {
            removeApplicationFromShowUpSubmit($applicantBox);
        }
    });

    $showUp.on('click', '.js-show-up-applicant-box-validated', function(e) {
        e.preventDefault();

        var $applicantBox = $(this);
        handleApplicantBoxDisplay($applicantBox);

        if (!$applicantBox.hasClass('js-show-up-applicant-box-clicked')) {
            addApplicationToShowUpSubmit($applicantBox);
        } else {
            removeApplicationFromShowUpSubmit($applicantBox);
        }
    });

    $showUpApplicantBoxesPartner.click(function() {
        var $applicantBox = $(this);

        if ($applicantBox.hasClass('js-show-up-applicant-box-clicked')) {
            removeApplicationFromShowUpSubmit($applicantBox);
        } else {
            //This needs to be done in an "else" condition, otherwise, the partner confirmation modal will be displayed
            //because modal display is trigerred after having added the attribute "data-toggle"
            $applicantBox.attr('data-toggle', 'modal');
        }
    });

    $showUpPartnerModalConfirms.click(function(e) {
        var partnerModalId = $(e.target).closest('.js-show-up-partner-modal').attr('id');
        var $relatedApplicantBox = $showUpApplicantBoxesPartner.filter(`[data-target='#${partnerModalId}']`);
        $relatedApplicantBox.addClass('js-show-up-applicant-box-clicked');
        $relatedApplicantBox.removeAttr('data-toggle');

        handleApplicantBoxDisplay($relatedApplicantBox);
        addApplicationToShowUpSubmit($relatedApplicantBox);
    });

    $showUpValidationForm.submit(function(e) {
       e.preventDefault();

        $.ajax({
            type: 'POST',
            url: $showUpValidationForm.attr('action'),
            data: $showUpValidationForm.serialize(),
            success: function(data) {
                $showUpPostSubmitModalTitle.text(data['title']);
                $showUpPostSubmitModalMessage.text(data['message']);
                $showUpValidationModal.modal('hide');
                $showUpValidationForm.trigger('custom_form_finished_loading');
                $showUpPostSubmitModal.modal('show');

                if (data['validated_show_ups']) {
                    $showUpList.find('li').remove();
                    $showUpApplicationListInput.find('input').remove();

                    $.each($showUp.find('.js-show-up-applicant-box-clicked'), function () {
                        $(this).find('.js-show-up-applicant-box-check').removeClass('d-none');
                        $(this).find('.js-show-up-applicant-box-cross').addClass('d-none');
                        $(this).removeClass('js-show-up-applicant-box-clicked').removeClass('applicants-box-not-showed-up-clicked').removeClass('applicants-box-not-showed-up').removeClass('js-show-up-applicant-box').removeAttr('data-target');
                        var unsetShowUpModalId = $(this).closest('.js-show-up-applicants-box-container').find('.js-show-up-unset-show-up-modal').attr('id');
                        $(this).attr('data-toggle', 'modal');
                        $(this).attr('data-target', `#${unsetShowUpModalId}`);
                    });
                }

                return false;
            }
        });
    })
});
