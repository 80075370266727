$(document).ready(function() {
    var $memberChoice = $('.js-member-choice');

    if (0 === $memberChoice.length) {
        return;
    }

    var selectTwoOptions = {
        dropdownAutoWidth: true,
        allowClear: false,
        dropdownCssClass: 'entity-selector-dropdown-container',
        width: '100%',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        multiple: true,
        maximumSelectionLength: 1,
        minimumInputLength: 2,
        positionDropdown: true,
        language: {
            noResults: function() {
                return 'Utilisateur inconnu';
            }
        }
    };

    if ($memberChoice[0].hasAttribute('data-select-url')) {
        var formatEntitySelection = function (member) {
            if (member.firstname != undefined) {
                return member.firstname + ' ' + member.lastname;
            }

            return member.text;
        };

        var formatEntity  = function(member) {
            if (member.email != undefined) {
                var markup = "<div class='select2-result-member'>" +
                    "<div class='select2-result-member__title'>" + member.firstname + ' ' + member.lastname + "</div>" +
                    "<div style='font-size: 12px'>(" + member.email + ")" +
                    "</div>";

                return markup;
            }
        };

        var url;

        if ($memberChoice[0].hasAttribute('data-only-coach')) {
            url = Routing.generate('member_select', {'only_coach': true});
        } else {
            url = Routing.generate('member_select');
        }

        selectTwoOptions['ajax'] = {
            url: url,
            cache: true,
            dataType: 'json',
            delay: 250,
            placeholder: 'Nom, prénom ou email du membre',
            data: function (params) {
                return {terms: params.term};
            },
            processResults: function (data) {
                return {
                    results: data
                };
            }
        };

        selectTwoOptions['templateSelection'] = formatEntitySelection;
        selectTwoOptions['templateResult'] = formatEntity;
    }

    $memberChoice.select2(selectTwoOptions);
});
