$(document).ready(function() {
    var $memberPassInfos = $('.js-member-pass-infos');

    if (0 === $memberPassInfos.length) {
        return;
    }

    var $memberPassInfosForm = $memberPassInfos.find('.js-member-pass-infos-form');
    var $memberPassInfosModal = $memberPassInfos.find('.js-member-pass-infos-modal');
    var $memberPassInfosErrors = $memberPassInfos.find('.js-member-pass-infos-errors');

    $memberPassInfosForm.submit(function(e) {
        e.preventDefault();
        $memberPassInfosErrors.empty();

        $.ajax({
            type: 'POST',
            url: Routing.generate('partner_pass_view_attributions'),
            data: $memberPassInfosForm.serializeArray(),
            dataType: 'json',
            success: function (data) {
                $memberPassInfosForm.trigger('custom_form_finished_loading');

                if ('undefined' !== typeof data['member_pass_infos']) {
                    $memberPassInfosModal.find('.js-member-pass-infos-modal-body').html($.parseHTML(data['member_pass_infos']));
                    $memberPassInfosModal.find('.js-member-pass-infos-modal-title').text(data['modal_title']);
                    $memberPassInfosModal.modal('show');
                } else {
                    $.each(data['errors'], function(index, errorMessage) {
                        $memberPassInfosErrors.append($(`<li>${errorMessage}</li>`))
                    });
                }
            }
        });
    })
});
