$(document).ready(function () {
    var $clickTransmitters = $('.js-click-transmitter');

    if (0 === $clickTransmitters.length) {
        return;
    }

    $.each($clickTransmitters, function(){
        var $clickTransmitter = $(this);
        var $clickTransmitterTrigger = $clickTransmitter.find('.js-click-transmitter-trigger');
        var $clickTransmitterTarget = $clickTransmitter.find('.js-click-transmitter-target');

        if (0 === $clickTransmitterTarget.length) {
            var $targetId = $clickTransmitterTrigger.attr('target');
            $clickTransmitterTarget = $($targetId);
        }

        $clickTransmitterTrigger.click(function (e) {
            e.preventDefault();

            if ($clickTransmitterTarget.hasClass('js-click-transmitter-nav-link')) {
                $clickTransmitterTarget.tab('show');

                $('html, body').animate({
                    scrollTop: $clickTransmitterTarget.offset().top
                }, 700);

                return;
            }

            $clickTransmitterTarget.trigger('click');

            if ($clickTransmitterTarget.is('input')) {
                $clickTransmitterTarget.trigger('focus');
            }
        });
    });
});
