import '../cdn_javascripts/jquery-ui.min';
import '../cdn_javascripts/jquery.inputmask.bundle.min';
import '../cdn_javascripts/jquery.mask.min';
import '../cdn_javascripts/jquery.mCustomScrollbar.min';
import '../cdn_javascripts/jquery.mousewheel.min';
import '../cdn_javascripts/jquery.scrollTo.min';
import '../cdn_javascripts/select2.full.min';
import '../cdn_javascripts/slick.min';
import '../cdn_javascripts/bootstrap-datepicker.min';
import '../cdn_javascripts/bootstrap-datepicker.fr.min';
import 'popper.js/dist/umd/popper-utils.min';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../cdn_javascripts/jquery.bootstrap-dropdown-hover.min';

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-73697980-5', 'auto');
ga('send', 'pageview');

// Hotjar Tracking Code for https://www.allsessions.club/
(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:375856,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
