$(document).ready(function() {
    var $sessionCarousel = $('.js-session-carousel');

    if (0 === $sessionCarousel.length) {
        return;
    }

    $sessionCarousel.removeClass('d-none');

    $sessionCarousel.slick({
        centerMode: false,
        slidesToScroll: 1,
        rows: 0,
        slidesToShow: 4,
        prevArrow: '<a href="#" class="slick-prev js-link-disabling-excluded"><i class="fas fa-chevron-left"></i></a>',
        nextArrow: '<a href="#" class="slick-next js-link-disabling-excluded"><i class="fas fa-chevron-right"></i></a>',

        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 4
            }
        },{
            breakpoint: 992,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 2
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1
            }
        }]
    });
});
