$(document).ready(function () {
    var $coachCompensationDetailTriggers = $('.js-coach-compensation-detail-trigger');

    if (0 === $coachCompensationDetailTriggers.length) {
        return;
    }

    var $coachCompensationDetailModal = $('.js-coach-compensation-details');

    $coachCompensationDetailTriggers.click(function(e) {
        e.preventDefault();
        var $detailTrigger = $(e.target);
        var $detailLoading = $detailTrigger.next('.js-coach-compensation-detail-loading');

        $detailTrigger.addClass('d-none');
        $detailLoading.removeClass('d-none');

        var coachId = $detailTrigger.attr('data-coach-id');
        var date = $detailTrigger.attr('data-date');

        var route = Routing.generate('admin_coach_compensation', {'member': coachId});
        var urlData = {'date': JSON.stringify(date)};

        $.ajax({
            url: route,
            data: urlData,
            success: function(data) {
                $coachCompensationDetailModal.find('.js-coach-compensation-details-body').html($.parseHTML(data['details_view']));
                $coachCompensationDetailModal.modal();
                $detailLoading.addClass('d-none');
                $detailTrigger.removeClass('d-none');
            }
        });
    });
});
