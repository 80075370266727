$(document).ready(function () {
    $toggleSets = $('.js-toggle-set');

    if (0 === $toggleSets.length) {
        return;
    }

    $toggleSets.on('click', '.js-toggle-set-trigger, .js-toggle-set-hide-trigger', function(e) {
        var $toggleSetContainer = $(this).closest('.js-toggle-set-container');
        $toggleSetContainer.find('.js-toggle-set-trigger').toggleClass('d-none');
        $toggleSetContainer.find('.js-toggle-set-target').toggleClass('d-none');
    });
});
