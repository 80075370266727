$(document).ready(function() {
    var $linkDisabling = $('.js-link-disabling');

    if (0 === $linkDisabling.length) {
        return;
    }

    var $linkDisablingPopup = $linkDisabling.find('.js-link-disabling-login-popup').first();

    $linkDisabling.on('click', 'a, button', function(e) {
        $clickedElement = $(e.target);

        if (0 < $clickedElement.closest('.js-link-disabling-excluded').length) {
            return;
        }

        //This condition covers external modals like the Hubspot ones
        if (0 < $clickedElement.closest('[aria-modal="true"]').length) {
            return;
        }

        e.preventDefault();

        $linkDisablingPopup.modal();
    });
});
