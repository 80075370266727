$(document).ready(function () {
    $tabDispplayTriggers = $('.js-tab-display-trigger');

    if (0 === $tabDispplayTriggers.length) {
        return;
    }

    $tabDispplayTriggers.click(function () {
        var $targetTabSection = $($(this).attr('href'));

        //When there is a page with tab, with our current system, only one tab
        //can display its elements with infinite scroll
        //So if the user is on this tab (which means he has clicked on its label),
        //then we should enable infinite scroll
        //otherwise, we disable infinite scroll because it is not used on the visible tab
        if ($(this)[0].hasAttribute('data-trigger-infinite-scroll')) {
            $(this).trigger('infinite_scroll_enable');
        } else {
            $(this).trigger('infinite_scroll_disable');
        }

        $tabSectionInfiniteScroll = $targetTabSection.find('.js-tab-section-infinite-scroll');

        if (0 < $tabSectionInfiniteScroll.length) {
            $tabSectionInfiniteScroll.trigger('infinite_scroll_enable_for_tab');
        }
    });

    $tabDispplayTriggers.filter('.js-tab-display-trigger-active').first().trigger('click');
});
