$(document).ready(function () {
    var $nbApplicationsFormContainer = $('.js-nb-applications-form-container');

    if (0 === $nbApplicationsFormContainer.length) {
        return;
    }

    var singleApplicationPrice = parseFloat($nbApplicationsFormContainer.attr('data-single-application-price'));
    var sessionId = parseInt($nbApplicationsFormContainer.attr('data-session-id'));
    var minimumAmount = parseFloat($nbApplicationsFormContainer.attr('data-minimum-amount'));
    var currentAccountBalance = parseFloat($nbApplicationsFormContainer.attr('data-current-account-balance'));
    var currentInvitationId = null;
    var currentMembershipId = null;

    if ($nbApplicationsFormContainer[0].hasAttribute('data-current-invitation-id')) {
        currentInvitationId = parseInt($nbApplicationsFormContainer.attr('data-current-invitation-id'));
    }

    if ($nbApplicationsFormContainer[0].hasAttribute('data-current-membership-id')) {
        currentMembershipId = parseInt($nbApplicationsFormContainer.attr('data-current-membership-id'));
    }

    var $nbApplicationsForm = $nbApplicationsFormContainer.find('.js-nb-applications-form');
    var $nbApplicationsFormMinimumRecharge = $nbApplicationsFormContainer.find('.js-nb-applications-form-minimum-recharge');
    var $nbApplicationsFormNbInput = $nbApplicationsFormContainer.find('.js-nb-applications-form-nb-input');
    var $nbApplicationsFormMissingCreditsContainer = $nbApplicationsFormContainer.find('.js-nb-applications-form-missing-credits-container');
    var $nbApplicationsFormMissingCredits = $nbApplicationsFormContainer.find('.js-nb-applications-form-missing-credits');
    var $nbApplicationsFormEnoughCreditsContainer = $nbApplicationsFormContainer.find('.js-nb-applications-enough-credits-container');
    var $nbApplicationsFormSelectedNbTotalApplications = $nbApplicationsFormContainer.find('.js-nb-applications-form-selected-nb-total-applications');
    var $nbApplicationsFormTotalPrice = $nbApplicationsFormContainer.find('.js-nb-applications-form-total-price');

    $nbApplicationsFormNbInput.change(function() {
        var nbApplications = 1;

        if ($nbApplicationsFormNbInput.val()) {
            nbApplications = parseInt($nbApplicationsFormNbInput.val());
        }

        var totalPrice = nbApplications * singleApplicationPrice;
        var missingCredits = totalPrice - currentAccountBalance;

        $nbApplicationsFormSelectedNbTotalApplications.text(nbApplications);
        $nbApplicationsFormTotalPrice.text(
            totalPrice
                .toLocaleString(undefined, {minimumFractionDigits: 2})
                .replace(',',' ')
                .replace('.',',')
        );

        if (null !== currentMembershipId) {
            if (null !== currentInvitationId) {
                $nbApplicationsForm.attr('action', Routing.generate('invitation_accept_with_membership_and_multiple_applications', {'invitation': currentInvitationId, 'membership': currentMembershipId}));
            } else {
                $nbApplicationsForm.attr('action', Routing.generate('application_apply_with_membership_and_multiple_applications', {'session': sessionId, 'membership': currentMembershipId}));
            }
        } else if (0 < missingCredits) {
            $nbApplicationsFormMissingCredits.text(
                missingCredits
                    .toLocaleString(undefined, {minimumFractionDigits: 2})
                    .replace(',',' ')
                    .replace('.',',')
            );

            if (missingCredits < minimumAmount) {
                $nbApplicationsFormMinimumRecharge.removeClass('d-none');
            } else {
                $nbApplicationsFormMinimumRecharge.addClass('d-none');
            }

            $nbApplicationsFormMissingCreditsContainer.removeClass('d-none');
            $nbApplicationsFormEnoughCreditsContainer.addClass('d-none');
            $nbApplicationsForm.attr('action', Routing.generate('payment_card_prepare_for_session_with_multiple_applications', {'session': sessionId, 'nb_applications': nbApplications}))
        } else {
            $nbApplicationsFormMissingCreditsContainer.addClass('d-none');
            $nbApplicationsFormEnoughCreditsContainer.removeClass('d-none');

            if (null !== currentInvitationId) {
                $nbApplicationsForm.attr('action', Routing.generate('invitation_accept_with_credits_and_multiple_applications', {'invitation': currentInvitationId}));
            } else {
                $nbApplicationsForm.attr('action', Routing.generate('application_apply_with_credits_and_multiple_applications', {'session': sessionId}));
            }
        }
    });
});

