$(document).ready(function () {
    var $autoloadPopup = $('.js-autoload-popup');

    if (0 === $autoloadPopup.length) {
        return;
    }

    $autoloadPopup.first().modal('show');
});

