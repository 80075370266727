$(document).ready(function () {
    var $comment = $('.js-comment');
    var $commentInput = $comment.find('.js-comment-input');
    var commentSessionId = $comment.attr('data-session-id');
    var $commentFormModal = $comment.find('.js-comment-form-modal');
    var $commentForm = $comment.find('.js-comment-form');
    var $commentFormError = $commentForm.find('.js-comment-form-error');
    var $commentListContainer = $comment.find('.js-comment-list-container');
    var $commentDisplayedCommentsList = $comment.find('.js-comment-displayed-comments-list');
    var $commentEditFormModal = $comment.find('.js-comment-edit-form-modal');
    var $commentEditForm = $commentEditFormModal.find('.js-comment-edit-form');
    var $commentEditFormError = $commentEditForm.find('.js-comment-form-error');
    var $commentDeactivateFormModal = $comment.find('.js-comment-deactivation-form-modal');
    var $commentDeactivateForm = $comment.find('.js-comment-deactivation-form');

    var $commentCollapsibleList = $commentListContainer.find('.js-comments-collapsible-list');
    var $commentCollapsibleTriggerContainer = $comment.find('.js-comment-collapsible-trigger-container');
    var $commentCollapsibleTrigger = $commentCollapsibleTriggerContainer.find('.js-comment-collapsible-trigger');
    var $commentCollapseAction = $commentCollapsibleTrigger.find('.js-comment-collapse-action');
    var $commentHideAction = $commentCollapsibleTrigger.find('.js-comment-hide-action');

    $commentForm.on('submit', function(e) {
        e.preventDefault();
        $commentFormError.addClass('d-none');
        $(this).trigger('custom_form_loading');

        addComment();
    });

    var pushCommentToCollapsibleList = function ($comment) {
        $commentCollapsibleList.prepend($comment);
        $commentCollapsibleTriggerContainer.removeClass('d-none');
    };

    $commentCollapsibleTrigger.click(function() {
        $commentCollapseAction.toggleClass('d-none');
        $commentHideAction.toggleClass('d-none');
    });

    var addComment = function() {
        var route = Routing.generate('session_add_comment', {'session': commentSessionId});

        $.ajax({
            type: 'POST',
            url: route,
            data: $commentForm.serialize(),
            success: function(data) {
                if (data['posted_comment']) {
                    $commentDisplayedCommentsList.prepend(data['comment_view']);

                    var $displayedComments = $commentDisplayedCommentsList.find('.js-comment-item');

                    if (3 < $displayedComments.length) {
                        $commentCollapsibleTriggerContainer.removeClass('d-none');
                        pushCommentToCollapsibleList($displayedComments.eq(3))
                    }

                    $commentInput.val('');
                    $commentFormError.addClass('d-none');
                    $commentFormModal.modal('hide');
                    $commentForm.trigger('custom_form_finished_loading');

                    return false;
                }

                $commentForm.trigger('custom_form_finished_loading');
                $commentFormError.removeClass('d-none');
            },
        });
    };

    $commentListContainer.on('click', '.js-comment-action-edit', function(e) {
        e.preventDefault();
        var $commentItem = $(e.target).closest('.js-comment-item');
        var $commentMessage = $commentItem.find('.js-comment-message').text();
        var commentId = $commentItem.attr('data-comment-id');

        $commentEditForm.attr('action', Routing.generate('comment_edit', {'comment': commentId}));
        $commentEditForm.attr('data-comment-id', commentId);
        $commentEditForm.find('.js-comment-input').val($commentMessage);
        $commentEditFormError.addClass('d-none').empty();
        $commentEditFormModal.modal('show');
    });

    $commentEditForm.submit(function(e) {
        e.preventDefault();
        var commentId = $commentEditForm.attr('data-comment-id');
        $(this).trigger('custom_form_loading');

        $.ajax({
            type: 'POST',
            url: $commentEditForm.attr('action'),
            data: $commentEditForm.serialize(),
            success: function(data) {
                if (data['edited_comment']) {
                    var $commentItem = $commentListContainer.find(`.js-comment-item[data-comment-id='${commentId}']`);
                    $commentItem.find('.js-comment-message').text($commentEditForm.find('.js-comment-input').val());
                    $commentItem.find('.js-comment-modified-status').removeClass('d-none');
                    $commentItem.find('.js-comment-item-author-intro').addClass('messages-block-list-title-modified');

                    $commentEditFormModal.modal('hide');
                    $commentEditFormError.addClass('d-none');
                } else {
                    $commentEditFormError.removeClass('d-none');
                }

                $commentEditForm.trigger('custom_form_finished_loading');

                return false;
            }
        });
    });

    var pullFirstCollapsibleComment = function() {
        var $collapsedComments = $commentCollapsibleList.find('.js-comment-item');

        if (0 < $collapsedComments.length) {
            $commentDisplayedCommentsList.append($collapsedComments.first());

            if (1 === $collapsedComments.length)  {
                $commentCollapsibleTriggerContainer.addClass('d-none');
            }
        }
    };

    $commentListContainer.on('click', '.js-comment-action-deactivate', function(e) {
        e.preventDefault();
        var $commentItem = $(e.target).closest('.js-comment-item');
        var $commentMessage = $commentItem.find('.js-comment-message').text();
        var commentId = $commentItem.attr('data-comment-id');

        $commentDeactivateForm.attr('action', Routing.generate('comment_deactivate', {'comment': commentId}));
        $commentDeactivateForm.attr('data-comment-id', commentId);
        $commentDeactivateForm.find('.js-comment-content').text($commentMessage);
        $commentDeactivateFormModal.modal('show');
    });

    $commentDeactivateForm.submit(function(e) {
        e.preventDefault();
        var commentId = $commentDeactivateForm.attr('data-comment-id');
        $(this).trigger('custom_form_loading');

        $.ajax({
            type: 'POST',
            url: $commentDeactivateForm.attr('action'),
            data: $commentDeactivateForm.serialize(),
            success: function(data) {
                if (data['deactivated_comment']) {
                    $commentListContainer.find(`.js-comment-item[data-comment-id='${commentId}']`).remove();
                    pullFirstCollapsibleComment();
                    $commentDeactivateFormModal.modal('hide');
                    $commentDeactivateForm.trigger('custom_form_finished_loading');
                }

                return false;
            }
        });
    });
});
