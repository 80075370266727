$(document).ready(function() {
    var $organizationSelectors = $('.js-organization-selector');

    var dataPerOrganizationType = {
        'team': {
            'path': 'team_search_teams',
            'empty_result_message': 'équipe inconnue',
        },
        'corporation_group': {
            'path': 'corporation_group_search_corporation_groups',
            'empty_result_message': 'groupe inconnu',
        },
    }

    if (0 === $organizationSelectors.length) {
        return;
    }

    var formatEntitySelection = function (organization) {
        if (organization.name != undefined) {
            return organization.name;
        }

        return organization.text;
    };

    var formatEntity  = function(organization) {
        if (organization.name != undefined) {
            var markup = "<div class='select2-result-member'>" + "<div class='select2-result-member__title'>" + organization.name + "</div>";

            if (organization.address) {
                markup += "<div style='font-size: 12px'>(" + organization.address + ")"  + "</div>";
            }

            return markup;
        }
    };

    $.each($organizationSelectors, function() {
        var $organizationSelector = $(this);
        var $organizationSelectorInput = $(this).find('select[data-toggle="select2"].js-organization-selector-input');
        var organizationType = $organizationSelector.attr('data-organization-type');

        var select2Parameters = {
            dropdownAutoWidth: true,
            dropdownCssClass: 'entity-selector-dropdown-container',
            width: '100%',
            allowClear: true,
            tags: false,
            positionDropdown: true,
            ajax: {
                url: Routing.generate(dataPerOrganizationType[organizationType]['path']),
                cache: true,
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        query: params.term // search term
                    };
                },
                processResults: function (data) {
                    return {
                        results: data
                    };
                }
            },
            escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
            multiple: false,
            minimumInputLength: 2,
            templateResult: formatEntity,
            templateSelection: formatEntitySelection,
            language: {
                noResults: function() {
                    return dataPerOrganizationType[organizationType]['empty_result_message'];
                },
                inputTooShort: function() {
                    return 'Renseigne 2 caractères ou plus';
                }
            },
        };

        var teamSelectorElement = $organizationSelectorInput.select2(select2Parameters);

        if ($organizationSelector[0].hasAttribute('data-dropdown-container-css-class')) {
            teamSelectorElement.data('select2').$dropdown.addClass($organizationSelector.attr('data-dropdown-container-css-class'))
        }

        if ($organizationSelector[0].hasAttribute('data-organization-id')) {
            var dataCurrentTeamId = $organizationSelector.attr('data-organization-id');
            $organizationSelectorInput.val(dataCurrentTeamId).trigger('change');
        }
    });
});
