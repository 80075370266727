$(document).ready(function() {
    var $membershipInfos = $('.js-membership-infos');

    if (0 === $membershipInfos.length) {
        return;
    }

    var $membershipInfosForm = $membershipInfos.find('.js-membership-infos-form');
    var $membershipInfosModal = $membershipInfos.find('.js-membership-infos-modal');
    var $membershipInfosErrors = $membershipInfos.find('.js-membership-infos-errors');

    $membershipInfosForm.submit(function(e) {
        e.preventDefault();
        $membershipInfosErrors.empty();

        $.ajax({
            type: 'POST',
            url: Routing.generate('subscription_view_memberships'),
            data: $membershipInfosForm.serializeArray(),
            dataType: 'json',
            success: function (data) {
                $membershipInfosForm.trigger('custom_form_finished_loading');

                if ('undefined' !== typeof data['membership_infos']) {
                    $membershipInfosModal.find('.js-membership-infos-modal-body').html($.parseHTML(data['membership_infos']));
                    $membershipInfosModal.find('.js-membership-infos-modal-title').text(data['modal_title']);
                    $membershipInfosModal.modal('show');
                } else {
                    $.each(data['errors'], function(index, errorMessage) {
                        $membershipInfosErrors.append($(`<li>${errorMessage}</li>`))
                    });
                }
            }
        });
    })
});
