$(document).ready(function () {
    var $customFormContainer = $('.js-custom-form-container');

    if (0 === $customFormContainer.length || $customFormContainer[0].hasAttribute('data-disable-loading-logic')) {
        return;
    }

    $customFormContainer.on('custom_form_loading', '.js-custom-form', function () {
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-button').addClass('d-none');
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-secondary').addClass('d-none');
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-loading-button').removeClass('d-none');
    });

    $customFormContainer.on('submit', '.js-custom-form', function () {
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-button').addClass('d-none');
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-secondary').addClass('d-none');
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-loading-button').removeClass('d-none');
    });

    $customFormContainer.on('custom_form_finished_loading', '.js-custom-form', function () {
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-button').removeClass('d-none');
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-secondary').removeClass('d-none');
        $(this).closest('.js-custom-form-wrapper').find('.js-custom-form-loading-button').addClass('d-none');
    });
});
