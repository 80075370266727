$(document).ready(function() {
    var $distanceSelector = $('.js-distance-selector');

    if (0 === $distanceSelector.length) {
        return;
    }

    var $distanceSelectorContainer = $distanceSelector.find('.js-distance-selector-container');
    var $distanceSelectorChoices = $distanceSelector.find('.js-distance-selector-choice');
    var $distanceSelectorChoicesList = $distanceSelector.find('.js-distance-selector-choices-list');
    var $distanceSelectorChosenDistance = $distanceSelector.find('.js-distance-selector-chosen-distance');
    var $distanceSelectorChosenDistanceFigure = $distanceSelector.find('.js-distance-selector-chosen-distance-figure');
    var $distanceSelectorCaretDown = $distanceSelector.find('.js-distance-selector-caret-down');
    var $distanceSelectorCaretUp = $distanceSelector.find('.js-distance-selector-caret-up');
    var $distanceSelectorTarget = $distanceSelector.find('.js-distance-selector-target');

    $distanceSelectorChosenDistance.click(function(e) {
        //prevent event from being caught by other listeners
        e.preventDefault();
        toggleChoicesList();

        return false;
    });

    $distanceSelectorChoices.click(function () {
        var selectedDistance = $(this).attr('data-distance');
        var currentDistance = $distanceSelectorContainer.attr('data-current-distance');

        if (currentDistance === selectedDistance) {
            return;
        }

        $distanceSelectorChosenDistanceFigure.html(selectedDistance);
        $distanceSelectorContainer.attr('data-current-distance', selectedDistance);
        $distanceSelectorTarget.val(parseInt(selectedDistance));

        $(this).trigger('data_current_distance_change');
    });

    $(document).click(function (event) {
        if (!$distanceSelectorChoicesList.hasClass('d-none') && !$(event.target).closest('.js-distance-selector-container').length) {
            toggleChoicesList();
        }
    });

    function toggleChoicesList()
    {
        if ($distanceSelectorChoicesList.hasClass('d-none')) {
            $distanceSelectorChoicesList.removeClass('d-none');
            $distanceSelectorCaretDown.addClass('d-none');
            $distanceSelectorCaretUp.removeClass('d-none');
        } else {
            $distanceSelectorChoicesList.addClass('d-none');
            $distanceSelectorCaretUp.addClass('d-none');
            $distanceSelectorCaretDown.removeClass('d-none');
        }
    }
});
