$(document).ready(function() {
    var $partnerPassGrant = $('.js-partner-pass-grant');

    if (0 === $partnerPassGrant.length) {
        return;
    }

    var $partnerPassGrantForm = $partnerPassGrant.find('.js-partner-pass-grant-form');
    var $partnerPassGrantFormMemberInput = $partnerPassGrant.find('.js-partner-pass-grant-member-input');
    var $partnerPassGrantConfirmationModal = $partnerPassGrant.find('.js-partner-pass-grant-confirmation-modal');
    var $partnerPassGrantConfirmationForm = $partnerPassGrantConfirmationModal.find('.js-partner-pass-grant-confirmation-form');

    $partnerPassGrantForm.submit(function(e) {
        e.preventDefault();

        var route = Routing.generate('partner_pass_grant_member', {'partnerPass': $partnerPassGrantForm.attr('data-partner-pass-id'), 'member': $partnerPassGrantFormMemberInput[0].value});

        $.ajax({
            type: 'POST',
            url: $partnerPassGrantForm.attr('action'),
            data: $partnerPassGrantForm.serializeArray(),
            dataType: 'json',
            success: function (data) {
                if ('undefined' !== typeof data['grant_confirmation_body']) {
                    $partnerPassGrantConfirmationModal.find('.js-partner-pass-grant-confirmation-modal-body').html($.parseHTML(data['grant_confirmation_body']));
                    $partnerPassGrantConfirmationForm.removeClass('d-none');
                    $partnerPassGrantConfirmationForm.attr('action', route);
                } else {
                    $partnerPassGrantConfirmationModal.find('.js-partner-pass-grant-confirmation-modal-body').html($.parseHTML(data['denied_confirmation_body']));
                    $partnerPassGrantConfirmationForm.addClass('d-none');
                    $partnerPassGrantConfirmationForm.attr('action', null);
                }

                $partnerPassGrantConfirmationModal.modal('show');
                $partnerPassGrantForm.trigger('custom_form_finished_loading');
            }
        });
    });
});
